@import '@angular/cdk/overlay-prebuilt.css'

@import 'tailwindcss/base'
@import 'tailwindcss/components'
@import 'tailwindcss/utilities'

body, header
  position: relative
  height: 100%

body
  @apply text-[14px] leading-[20px] bg-[#0C111D] text-white
  font-family: 'Inter', sans-serif
  min-height: 100vh

a
  @apply text-white transition-all cursor-pointer
  &:hover
    @apply text-white cursor-pointer
  &:focus-visible
    @apply outline-none outline-0
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(152, 162, 179, 0.14)

button
  &:focus-visible
    @apply outline-none outline-0
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(152, 162, 179, 0.14)

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

*
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -moz-tap-highlight-color: rgba(0, 0, 0, 0)


.flex-box-row
  @apply flex flex-row

.flex-box-col
  @apply flex flex-col

.dialog-container
  @apply block bg-[#0C111D] rounded-[12px] border border-br-secondary
  box-shadow: 0 8px 8px -4px #10182808, 0 20px 24px -4px #10182814

@media (max-width: 640px)
  .cdk-overlay-pane
    bottom: 0!important
    position: fixed!important
  .dialog-container
    width: 100vw!important
    min-width: 100%!important
    max-width: 640px!important
    border-bottom-left-radius: 0!important
    border-bottom-right-radius: 0!important
    border-left: none
    border-right: none

.dialog-backdrop-blur
  background: #1F242F3D
  backdrop-filter: blur(25px)

.dialog-overlay-panel
  @apply flex flex-grow items-center justify-center
  height: 100vh

.dialog-backdrop
  @apply bg-[#1F242FA0]

.dialog-background
  @apply block bg-white shadow-lg rounded-[12px]

.dialog-header
  @apply flex flex-row items-center justify-between p-[16px] border-b border-br-secondary

.dialog-title
  @apply text-[16px] leading-[20px] text-white font-medium
  @apply sm:text-[18px] sm:leading-[28px] sm:font-semibold

.dialog-close
  @apply flex flex-row justify-center items-center min-w-[36px] w-[36px] h-[36px] rounded-[8px] transition-all
  &:hover
    @apply bg-[#1F242F]
    path
      @apply stroke-[#94969C]
  &[disabled]
    @apply bg-[#0C111D] cursor-default
    &:hover
      @apply bg-[#0C111D]
      path
        @apply stroke-[#85888E]
  path
    @apply transition-all

.dialog-content
  @apply p-[24px] pt-[16px]

.stag-layer
  @apply fixed left-0 top-0 right-0 bottom-0 overflow-hidden bg-[#0C111D] items-center justify-end
  z-index: 2
  &.hidden
    visibility: hidden
    opacity: 0
    transition: visibility 0s 300ms, opacity 300ms linear
  &-content
    @apply absolute  left-[50%] translate-x-[-50%] translate-y-[-50%] top-[75%]
    @apply flex justify-center items-center w-[800px] h-[800px] origin-center pointer-events-none select-none
    .stag-image
      @apply absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 origin-center
      &-01
        @apply top-[9.5%]
      &-02
        @apply top-[25%] animate-blink
        animation-delay: 1500ms
      &-03
        img
          @apply origin-center animate-spin-stag
      &-04
        img
          @apply animate-spin-stag-left
          transform-origin: center
      img
        @apply max-w-none max-h-none
  .shadow
    @apply flex flex-col gap-[36px] justify-center items-center text-center absolute left-0 bottom-0 right-0 min-h-[500px]
    background: linear-gradient(0deg, #0C111D 43.51%, rgba(12, 17, 29, 0) 100%)
    .title
      @apply text-[24px] leading-[34px] font-medium

.button
  @apply flex flex-row gap-[10px] justify-center items-center w-full leading-[28px] h-[60px] py-[16px] px-[22px] rounded-[60px]
  @apply text-center text-[18px] text-[#CECFD2] font-medium bg-[#161B26] border border-[#333741] transition-all
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05)
  &:hover
    @apply bg-[#1F242F] text-white
    path
      @apply stroke-white
  &.primary
    @apply bg-[#7F56D9] border-[#7F56D9] text-white
    &:hover
      @apply bg-[#6941C6] border-[#6941C6]
    &:focus-visible
      box-shadow: 0 0 0 4px #9E77ED3D,  0 1px 2px 0 #1018280D
      z-index: 1
    &[disabled]
      &:hover
        @apply bg-[#0C111D] border-br-secondary
      path
        @apply stroke-[#85888E]
  &[disabled]
    @apply bg-[#0C111D] border-br-secondary text-[#85888E] cursor-default
    &:hover
      @apply bg-[#0C111D] text-[#85888E]
      path
        @apply stroke-[#85888E]
  path
    @apply transition-all


@keyframes flash
  0%, 50%, 100%
    opacity: 0.2
  25%, 75%
    opacity: 0.1

@keyframes flash2
  0%, 50%, 100%
    opacity: 1
  25%, 75%
    opacity: 0.1

@keyframes spin2
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes blink
  50%
    opacity: 0

@keyframes blink2
  80%
    box-shadow: 0 0 0 4px #98A2B324
  100%
    box-shadow: 0 0 0 4px #98A2B300


@keyframes shine1
  0%
    background-position: 150% 0
  25%
    background-position: -50% 0
  100%
    background-position: -50% 0
